import React, { useState } from 'react';
import { Sun, Book, Clock, MapPin, Phone, Mail, Camera } from 'lucide-react';

const VictoryKindercareLandingPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "/images/1.png",
    "/images/2.png",
    "/images/3.png",
  ];

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-200 via-pink-200 to-blue-200 p-4 sm:p-8">
      <header className="text-center mb-8 sm:mb-12">
        <h1 className="text-4xl sm:text-5xl font-bold text-purple-700 mb-2">Victory Kindercare</h1>
        <p className="text-xl sm:text-2xl text-pink-600">Nurturing Young Minds, Inspiring Bright Futures!</p>
      </header>

      <main className="bg-white rounded-lg shadow-lg overflow-hidden mb-8">
        <section className="p-4 sm:p-8 mb-8">
          <h2 className="text-2xl sm:text-3xl font-semibold text-purple-600 mb-4">Welcome to Victory Kindercare!</h2>
          <p className="text-gray-700 text-base sm:text-lg">
            At Victory Kindercare, we believe in creating a nurturing and stimulating environment where every child can thrive. 
            Our dedicated team of educators is committed to fostering a love for learning, creativity, and social development.
          </p>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-8 mb-8 bg-gray-50">
          <div className="bg-yellow-100 p-4 rounded-lg flex items-center">
            <Sun className="text-yellow-500 mr-4" size={32} />
            <div>
              <h3 className="text-lg font-semibold text-yellow-700">Bright Beginnings</h3>
              <p className="text-gray-600 text-sm">Nurturing young minds with love and care</p>
            </div>
          </div>
          <div className="bg-pink-100 p-4 rounded-lg flex items-center">
            <Book className="text-pink-500 mr-4" size={32} />
            <div>
              <h3 className="text-lg font-semibold text-pink-700">Playful Learning</h3>
              <p className="text-gray-600 text-sm">Education through fun and engaging activities</p>
            </div>
          </div>
          <div className="bg-blue-100 p-4 rounded-lg flex items-center">
            <Clock className="text-blue-500 mr-4" size={32} />
            <div>
              <h3 className="text-lg font-semibold text-blue-700">Flexible Hours</h3>
              <p className="text-gray-600 text-sm">Accommodating busy family schedules</p>
            </div>
          </div>
        </section>

        <section className="p-4 sm:p-8 mb-8">
          <h2 className="text-2xl sm:text-3xl font-semibold text-purple-600 mb-4">Our Programs</h2>
          <ul className="list-disc list-inside text-gray-700 text-base sm:text-lg">
            <li>Early Literacy and Numeracy</li>
            <li>Arts and Crafts</li>
            <li>Music and Movement</li>
            <li>Nature Exploration</li>
            <li>Social Skills Development</li>
            <li>STEM Activities</li>
            <li>Bilingual Learning (English/Spanish)</li>
          </ul>
        </section>

        <section className="p-4 sm:p-8 mb-8 bg-gray-50">
          <h2 className="text-2xl sm:text-3xl font-semibold text-purple-600 mb-4">About Us</h2>
          <p className="text-gray-700 text-base sm:text-lg mb-4">
            Victory Kindercare is a licensed childcare facility operating as Victory Daycare Corp. 
            We are committed to providing high-quality early childhood education and care in a safe, 
            loving environment.
          </p>
          <p className="text-gray-700 text-base sm:text-lg">
            Our facility is led by a team of experienced professionals:
          </p>
          <ul className="list-disc list-inside text-gray-700 text-base sm:text-lg mt-2">
            <li>Kristina Vasileva - Chief Executive Officer</li>
            <li>Aleksandr Vasilev - Chief Financial Officer</li>
            <li>Ganna Sobchenko - Secretary</li>
          </ul>
        </section>

        <section className="p-4 sm:p-8 mb-8">
          <h2 className="text-2xl sm:text-3xl font-semibold text-purple-600 mb-4">Gallery</h2>
          <div className="relative">
            <img src={images[currentImage]} alt={`Gallery image ${currentImage + 1}`} className="w-full h-48 sm:h-64 object-cover rounded-lg" />
            <button onClick={prevImage} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md">
              &lt;
            </button>
            <button onClick={nextImage} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md">
              &gt;
            </button>
          </div>
          <p className="text-center mt-2 text-gray-600">Click to navigate through our gallery</p>
        </section>
      </main>

      <section className="bg-green-100 p-4 sm:p-8 rounded-lg shadow-lg mb-8">
        <h2 className="text-2xl sm:text-3xl font-semibold text-green-700 mb-4">Contact Us</h2>
        <div className="flex items-center mb-2">
          <MapPin className="text-green-500 mr-2 flex-shrink-0" size={20} />
          <p className="text-gray-700">1270 via Contessa, San Marcos, CA, 92069</p>
        </div>
        <div className="flex items-center mb-2">
          <Phone className="text-green-500 mr-2 flex-shrink-0" size={20} />
          <p className="text-gray-700">(877) 829-5500</p>
        </div>
        <div className="flex items-center mb-2">
          <Mail className="text-green-500 mr-2 flex-shrink-0" size={20} />
          <p className="text-gray-700">info@victorykindercare.com</p>
        </div>
        <div className="flex items-center">
          <Clock className="text-green-500 mr-2 flex-shrink-0" size={20} />
          <p className="text-gray-700">Open Monday to Friday, 7:30 AM - 6:00 PM</p>
        </div>
      </section>

      <footer className="text-center mt-8 sm:mt-12 text-gray-600">
        <p>&copy; 2024 Victory Kindercare. All rights reserved.</p>
        <p className="mt-2">Victory Daycare Corp. | EIN: 99-0831060</p>
        <p className="mt-2">
          <a href="https://daycare.news" className="text-blue-600 hover:underline">daycare.news</a>
        </p>
      </footer>
    </div>
  );
};

export default VictoryKindercareLandingPage;
